import './teaser-grid.scss';
import AjaxLoad from '../../lib/ajax-load';
import { getOffset, smoothScrollTo } from '../../js/helper';

class TeaserGrid {
    constructor (element, options) {
        this.$pagination = element;
        this.prevNext = [].slice.call(this.$pagination.querySelectorAll('[data-teaser-grid-pagination="url"]'));
        this.$overview = document.querySelector('[data-overview="root"]');
        this.$select = this.$pagination.querySelector('[data-teaser-grid-pagination="select"]');
        this.$target = document.querySelector('[data-teaser-grid="root"]');
        this.customEvent = new CustomEvent('ajaxload');

        const settings = {
            onSubmitted: null
        };

        this.settings = Object.assign({}, settings, options);
    }

    initialize () {
        this.setEvents();
    }

    setEvents () {
        for (const element of this.prevNext) {
            element.addEventListener('click', (e) => {
                e.preventDefault();
                this.loadContent(element.getAttribute('href'));
            });
        }

        this.$select.addEventListener('change', (e) => {
            if (e.detail.value !== '') {
                this.loadContent(e.detail.value);
            }
        });
    }

    setContent (resultText, callback) {
        console.log('set Content via Teaser Grid');
        const $newTeaser = [].slice.call(resultText.querySelectorAll('[data-overview="item"]'));
        let $nowTeaser = [];
        const $oldTeaser = [].slice.call(this.$target.querySelectorAll('[data-overview="item"]'));

        for (const element of $oldTeaser) {
            if (element.classList.contains('is--visible')) {
                element.classList.remove('is--visible');
            } else {
                element.classList.add('is--hidden');
            }
        }

        for (const element of $newTeaser) {
            element.classList.add('is--hidden');
        }

        setTimeout(() => {
            this.$target.innerHTML = resultText.innerHTML;
        }, 250 + ($newTeaser.length * 70));

        setTimeout(() => {
            $nowTeaser = [].slice.call(this.$target.querySelectorAll('[data-overview="item"]'));
            for (const element of $nowTeaser) {
                element.classList.add('is--visible');
            }
        }, 300 + ($newTeaser.length * 70));

        setTimeout(() => {
            this.$overview.classList.add('is--finished');
            this.$overview.classList.remove('is--loading');
            window.dispatchEvent(this.customEvent);
            smoothScrollTo(0, getOffset(this.$overview).top - 120, 750);

            for (const element of $nowTeaser) {
                element.classList.remove('is--hidden');
                element.classList.remove('is--visible');
            }

            if (typeof callback === 'function') {
                callback();
            }
        }, 1100 + ($newTeaser.length * 70));
    }

    loadContent (url) {
        this.$overview.classList.add('is--loading');

        const ajaxLoad = new AjaxLoad(url, {
            requestMethod: 'GET',
            onSuccess: (resultText) => {
                this.setContent(this.createElementFromHTML(resultText), () => {
                    setTimeout(() => {
                        this.$overview.classList.remove('is--finished');
                    }, 350);

                    /*
                    if (typeof this.settings.onSubmitted === 'function') {
                        this.settings.onSubmitted(this.$target);
                    }
                    */

                    const contentUpdateEvent = new CustomEvent('content.loaded', {
                        view: window,
                        bubbles: true,
                        cancelable: false,
                        detail: {
                            $context: this.$target
                        }
                    });

                    window.dispatchEvent(contentUpdateEvent);

                    window.initContent(this.$target);
                });
            }
        });

        ajaxLoad.initialize();
    }

    createElementFromHTML (htmlString) {
        const div = document.createElement('div');
        div.innerHTML = htmlString.trim();

        if (div.children.length > 1) {
            const itemsArray = [];
            Array.prototype.push.apply(itemsArray, div.children);
            return itemsArray;
        } else {
            return div.firstChild;
        }
    }

    // generateOverlay () {
    //     this.overlay = document.createElement('section');
    //     this.overlay.classList.add('site-overlay');
    //     this.overlay.setAttribute('data-overlay', 'root');
    //
    //     let template = '<div class="site-overlay__container">';
    //     template += '<div class="site-overlay__inner" data-overlay-target>';
    //     template += '<span class="site-overlay__back textlink textlink--arrow-left" data-overlay-close>Overview</span><span class="site-overlay__close" data-overlay-close></span></div></div>';
    //
    //     this.overlay.innerHTML = template;
    // }
    //
    // closeOverlay () {
    //     this.overlay.classList.remove('is--visible');
    //     document.body.classList.remove('has--overlay');
    //     setTimeout(() => {
    //         const $overlay = document.querySelector('[data-overlay="root"]');
    //         $overlay.remove();
    //         this.overlay = null;
    //     }, 1250);
    // }
}

export { TeaserGrid };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$teaserGrid = $context.querySelectorAll('[data-teaser-grid-pagination="root"]');

        for (let i = 0; i < $$teaserGrid.length; i++) {
            const teaserGrid = new TeaserGrid($$teaserGrid[i]);
            teaserGrid.initialize();
        }
    }
});
